<template>
	<form id="login-register">
		<EmailField :value="email" @emailUpdated="setEmail" ref="emailField" />
		<PasswordField :value="password" @passwordUpdated="setPassword" ref="passwordField" />
		<!-- add api errors component -->
		<Errors :errors="responseErrors" />
		<div class="buttons">
			<a id="reset-password" v-bind:href="resetPass">Forgotten details</a>
		
			<button class="btn-save btn-save--right button--login" @click.prevent="login"><span>Login</span> <i class="fa-duotone fa-right-to-bracket"></i></button>
		</div>
		<!-- end buttons -->
	</form>
</template>

<script>
import useValidate from "@vuelidate/core"
// import { required, email } from '@vuelidate/validators'

import EmailField from "./inputs/EmailField.vue"
import PasswordField from "./inputs/PasswordField.vue"
import Errors from "./validation/Errors.vue"

export default {
	components: {
		EmailField,
		PasswordField,
		Errors,
	},
	data() {
		return {
			v$: useValidate(),
			email: "",
			password: "",
			resetPass: "/reset-password",
			formIsValid: true,
			responseErrors: [],
		}
	},
	methods: {
		setEmail(value) {
			this.email = value
		},
		setPassword(value) {
			this.password = value
		},
		async login() {
			this.v$.$validate()
			// Guard clause, exit the function before sending
			// the API request if the form has errors
			if (this.v$.$errors.length > 0) {
				return (this.responseErrors = [{$message: "Form has errors, please check and try again."}])
			}

			let loginResponse = await this.$store.dispatch("auth/login", {email: this.email, password: this.password})
			this.responseErrors = [{$message: loginResponse}]
		},
		async logout() {
			this.$store.commit("unAuthenticate")
		},
	},
	computed: {
		computedErrorMessage() {
			if (this.errorMessage.length > 0) {
				return this.errorMessage
			}
			return ""
		},
	},
}
</script>
