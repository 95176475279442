<template>
	<div class="row">
		<label><span class="title">Email</span>
		<input name="email" type="text" id="email" class="form-control" :class="{error: v$.$error}" placeholder="Enter your email address" @blur="validate" :value="value" @input="updateEmail($event)" /></label>
		<IconErrors v-if="v$.emailValue.$error" :errors="v$.$errors" />
	</div>
</template>

<script>
import useValidate from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import IconErrors from "../validation/iconErrors.vue"
export default {
	props: ["value"],
	components: {
		IconErrors,
	},
	data() {
		return {
			v$: useValidate(),
			emailValue: this.value,
		}
	},
	methods: {
		updateEmail($event) {
			this.emailValue = $event.target.value
			this.$emit("emailUpdated", this.emailValue)
		},
		validate() {
			this.v$.$validate()
		},
	},
	validations() {
		return {
			emailValue: {required},
		}
	},
}
</script>
<style scoped>
label {
	margin-bottom: 20px;
	width: auto;
	margin-right: 10px;
	display: grid;
	grid-template-columns: 90px 1fr;
	align-items: center;

  .title {
		padding-right: 15px;
		text-align: right;
	}
}
input#email {
	width: 100%;
	margin-bottom: 0;
}
.error {
	width: 100%;
}
</style>
